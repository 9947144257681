import cn from 'classnames';
import * as React from 'react';
import BaseInput from './BaseInput';
import CreditCardInput from './CreditCardInput';
import { FieldInputProps } from 'formik';
import { Field as HField, InputProps as HInputProps } from '@headlessui/react';
import { BaseInputProps } from '../AbstractInput/AbstractInput';

export interface InputProps extends BaseInputProps, Omit<HInputProps, keyof BaseInputProps> {
  field: FieldInputProps<string>;
  secondaryMessage?: React.ReactNode | string | null;
  inputClassName?: string | null;
  center?: boolean;
  cc?: boolean;
  className?: string;
  inPlaceError?: boolean;
}

const Input = ({
  field,
  secondaryMessage = null,
  inputClassName = null,
  center = false,
  cc,
  ...props
}: InputProps) => (
  <HField>
    <div className={cn(center && props.inPlaceError ? '!pt-2' : '', 'flex w-full flex-col', props.className)}>
      {cc ? (
        <CreditCardInput className={inputClassName} {...field} {...props} />
      ) : (
        <BaseInput className={inputClassName} {...field} {...props} />
      )}
      {!!secondaryMessage && (
        <div className={cn('font-hvThin text-xs text-gray-500', !props.inPlaceError ? 'mt-2' : '')}>
          {secondaryMessage}
        </div>
      )}
    </div>
  </HField>
);

export default Input;
