import * as React from 'react';

import Checkbox, { CheckboxProps as ChProps } from '../Checkbox/Checkbox';
import { Field } from 'formik';
import RadioTab, { RadioProps as RdProps } from '../RadioTabs/RadioTab';
import Input, { InputProps as InpProps } from '../Input/Input';
import Combobox, { Props as CbProps } from '../Combobox/Combobox';
import Textarea, { TextAreaProps as TAProps } from '../Textarea/Textarea';
import Select, { SelectProps as SelProps } from '../Select/Select';
import DateTimePicker, { DateTimePickerProps as PickerProps } from '../DateTimePicker/DateTimePicker';

type ComponentType = 'input' | 'checkbox' | 'radio' | 'combobox' | 'textarea' | 'select' | 'dateTimePicker';

interface BaseProps {
  componentType: ComponentType;
  name: string;
  labelComponent: React.ReactNode | string;
  validate?: (value) => string;
}

interface RadioProps extends BaseProps, RdProps {
  componentType: 'radio';
}

interface InputProps extends BaseProps, Omit<InpProps, 'field'> {
  componentType: 'input';
}
interface TextAreaProps extends BaseProps, Omit<TAProps, 'field'> {
  componentType: 'textarea';
}

interface CheckboxProps extends BaseProps, Omit<ChProps, 'field'> {
  componentType: 'checkbox';
}

interface SelectProps extends BaseProps, Omit<SelProps, 'field'> {
  componentType: 'select';
}

interface ComboboxProps extends BaseProps, Omit<CbProps<string | number>, 'field'> {
  componentType: 'combobox';
  name: string;
}
interface DateTimePickerProps extends Omit<BaseProps, 'labelComponent'>, Omit<PickerProps, 'field' | 'form'> {
  componentType: 'dateTimePicker';
  labelComponent?: string;
}

type Props =
  | CheckboxProps
  | InputProps
  | RadioProps
  | ComboboxProps
  | TextAreaProps
  | SelectProps
  | DateTimePickerProps;

const component = (componentType: ComponentType) => {
  switch (componentType) {
    case 'checkbox':
      return Checkbox;
    case 'radio':
      return RadioTab;
    case 'input':
      return Input;
    case 'textarea':
      return Textarea;
    case 'select':
      return Select;
    case 'combobox':
      return Combobox;
    case 'dateTimePicker':
      return DateTimePicker;
    default:
      throw new Error('Invalid component type'); // this should not happen as prop types are statically checked
  }
};

const FormikFieldWrapper = ({ name, labelComponent, componentType, validate, ...props }: Props) => (
  <Field
    name={name}
    label={labelComponent}
    labelComponent={labelComponent}
    component={component(componentType)}
    validate={validate}
    {...props}
  />
);
export default FormikFieldWrapper;
