import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldInputProps, FormikProps, FormikValues, getIn } from 'formik';
import { ErrorMessage } from 'styleguide/components/forms';
import 'styleguide/components/ReactDatePicker/ReactDatePicker.css';
import { setHours, setMinutes } from 'date-fns';
import { Field as HField } from '@headlessui/react';

export interface DateTimePickerProps {
  field: FieldInputProps<string>;
  form: FormikProps<FormikValues>;
  onChange: (name: string, value: Date) => void;
  popperPlacement?: string;
  className?: string;
  wrapperClassName?: string;
  calendarClassName?: string;
  popperClassName?: string;
}

const DateTimePicker = ({
  field,
  form: { touched, errors },
  onChange,
  popperPlacement,
  className,
  wrapperClassName,
  calendarClassName,
  popperClassName,
  ...props
}) => (
  <div>
    <HField>
      <DatePicker
        className={className}
        wrapperClassName={wrapperClassName}
        calendarClassName={calendarClassName}
        popperClassName={popperClassName}
        popperPlacement={popperPlacement}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date: Date | null) => {
          onChange(field.name, date);
        }}
        showTimeSelect
        timeFormat="h:mmaa"
        timeIntervals={30}
        injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
        dateFormat="MMMM d, yyyy h:mm aa"
        timeCaption="time"
        {...props}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <ErrorMessage message={getIn(errors, field.name)} />
      )}
    </HField>
  </div>
);

export default DateTimePicker;
