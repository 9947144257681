import * as React from 'react';

import { Field as HField, Checkbox as HCheckbox, Label as HLabel } from '@headlessui/react';
import { DEFAULT_SIZE } from '../constants';
import type { FieldInputProps } from 'formik';

export interface CheckboxProps {
  field: FieldInputProps<boolean>;
  labelComponent: React.ReactNode | string;
  className?: string;
  size?: 'sm' | 'md';
  inputClassName?: string | null;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const Checkbox = ({
  field,
  labelComponent,
  className,
  size = DEFAULT_SIZE,
  inputClassName = null,
  disabled,
  onClick,
  ...props
}: CheckboxProps) => {
  let sizeClass;
  if (size === 'sm') {
    sizeClass = 'size-4';
  } else if (size === 'md') {
    sizeClass = 'size-8';
  } else {
    sizeClass = 'size-4';
  }

  return (
    <HField className={`flex items-center ${className ?? ''}`} disabled={disabled}>
      <HCheckbox
        onClick={onClick}
        className={`group ${sizeClass} ${inputClassName ?? ''}`}
        onChange={(value: boolean) => {
          field.onChange({ target: { value, name: field.name } });
        }}
        checked={field.value}
        {...props}
      >
        <div
          className={`${sizeClass} inline-block border-2 border-gray-200 rounded-s rounded-e bg-white group-data-[checked]:border-blue group-data-[checked]:bg-blue transition-colors group-hover:border-blue group-data-[disabled]:group-hover:border-gray-200 cursor-pointer group-data-[disabled]:cursor-auto`}
        >
          <svg
            className="align-top stroke-white opacity-0 group-data-[checked]:opacity-100"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </HCheckbox>
      <HLabel>{labelComponent} </HLabel>
    </HField>
  );
};

export default Checkbox;
