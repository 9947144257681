import cn from 'classnames';
import * as React from 'react';

import AbstractInput, { BaseInputProps } from '../AbstractInput/AbstractInput';
import { FieldInputProps } from 'formik';
import { TextareaProps as HTextareaProps, Field as HField } from '@headlessui/react';

export interface TextAreaProps extends BaseInputProps, Omit<HTextareaProps, keyof BaseInputProps> {
  field: FieldInputProps<string>;
  disabled?: boolean;
}

const Textarea = ({ field, ...props }) => (
  <HField>
    <AbstractInput
      {...props}
      {...field}
      component="textarea"
      className={cn('!resize-none', props.className)}
      disabled={props.disabled}
      {...props}
    />
  </HField>
);

export default Textarea;
