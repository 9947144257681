import * as React from 'react';
import AbstractInput, { BaseInputProps } from '../AbstractInput/AbstractInput';

export type Props = BaseInputProps & {
  disabled?: boolean;
};

const BaseInput = ({ type = 'text', className, ...otherProps }: Props) => (
  <AbstractInput {...otherProps} component="input" type={type} className={className} />
);

export default BaseInput;
